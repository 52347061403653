export const customFetch = async (url: string) => {
  return await fetch(process.env.ApiUrl + url).then(async (res) => {
    return { ...await res.json(), status: res.status }
  });
};

export const sendData = async (url: string, data: unknown) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return await fetch(process.env.ApiUrl + url, requestOptions).then(
    (res) => res);
};

export const putData = async (url: string, data: unknown) => {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return await fetch(process.env.ApiUrl + url, requestOptions).then(
    (res) => res);
};
