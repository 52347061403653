import { UilPhoneVolume, UilCheckCircle } from "@iconscout/react-unicons";
import { useState } from "react";
import { sendData } from "../../utilities/fetch";
import { ValidateEmail, ValidatePhoneNumber } from "../../utilities/validation";
import { getCookie } from 'cookies-next';

interface IContent {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  cancelButtonRef: React.MutableRefObject<null>;
  description?: string
}

const Content = (props: IContent) => {
  const [error, setError] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>(
    "Lütfen tüm alanları doldurduğunuzdan emin olun !"
  );
  const [success, setSuccess] = useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phonenumber, setPhonenumber] = useState<string>("");
  const [company, setCompany] = useState<string>("");

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    const formData = {
      name: name,
      email: email,
      phone: phonenumber,
      company: company,
      source_id: 7,
      description: (props.description ? props.description : "Demo Formu - popup"),
      utm_campaign: getCookie('utm_campaign', {}),
      utm_date: getCookie('utm_date', {}),
      utm_url: getCookie('utm_uri', {}),
    };

    if (
      name.length < 1 ||
      email.length < 1 ||
      phonenumber.length < 1 ||
      company.length < 1
    ) {
      setError(true);
      setErrMsg("Lütfen tüm alanları doldurduğunuzdan emin olun !");
    } else {
      if (ValidateEmail(email)) {
        if (ValidatePhoneNumber(phonenumber)) {
          const ret = await sendData("/leads/create", formData);

          if (ret.status != 200) {
            setError(true);
            setSuccess(false);
          } else {
            setError(false);
            setSuccess(true);
            //window.location.replace('/tesekkurler.html');
          }
        } else {
          setError(true);
          setErrMsg("Telefon numaranızı hatalı girdiniz");
        }
      } else {
        setError(true);
        setErrMsg("Email adresiniz hatalı girdiniz");
      }
    }
  };
  return (
    <div className="grid grid-cols1 md:grid-cols-2">
      <div className="w-full md:w-80 bg-blue-1000 p-8 text-white  rounded-tl-lg md:rounded-l-lg text-center">
        <h3 className="font-semibold mb-3 mt-3 text-lg">
          Ücretsiz denemek için
        </h3>
        <a href='tel:08503466600' className="text-white border border-white px-2 py-1 md:px-4 md:py-2 rounded-sm text-sm md:text-xl">
          <UilPhoneVolume className="inline mr-2" /> 0850{" "}
          <strong>346 66 00</strong>
        </a>
        <p className="text-sm leading-8 text-gray-300 mt-2">Bizi hemen arayın!</p>

        <div className="relative my-4 md:my-10">
          <hr className=" border-gray-800" />
          <div className="text-sm font-light text-gray-600 bg-blue-1000 mt-[-10px] w-10 text-center mx-auto ">
            veya
          </div>
        </div>

        <h5 className="font-semibold text-lg md:text-xl">
          Hemen formu doldurun <br /> 15 gün boyunca
          <span className="text-orange-500 block">ücretsiz deneyin!</span>
        </h5>
      </div>
      <form onSubmit={handleSubmit} className="p-3">
        {!success && (
          <>
            <h3 className="font-semibold text-base mt-4">Demo talep formu</h3>
            <p className="text-xs font-light leading-6">
              Formu hemen doldurun uzmanlarımız sizi arasın!
            </p>
            <input
              type="text"
              className="mt-6 border border-gray-200 px-2 py-2 rounded mb-4 w-full text-sm font-light"
              placeholder="Adınız Soyadınız"
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="text"
              className="border border-gray-200 px-2 py-2 rounded mb-4 w-full text-sm font-light"
              placeholder="E-Posta Adresiniz"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="text"
              className="border border-gray-200 px-2 py-2 rounded mb-4 w-full text-sm font-light"
              placeholder="Telefon"
              onChange={(e) => setPhonenumber(e.target.value)}
            />
            <input
              type="text"
              className="border border-gray-200 px-2 py-2 rounded mb-4 w-full text-sm font-light"
              placeholder="Firma Ünvanı"
              onChange={(e) => setCompany(e.target.value)}
            />

            <button
              className="mt-3 rounded-md bg-orange-500 hover:bg-orange-700 text-white font-semibold text-sm w-full text-center py-2"
              type="submit"
              //   onClick={() => props.setOpen(false)}
              ref={props.cancelButtonRef}
            >
              Hemen Ücretsiz Deneyin
            </button>
          </>
        )}
        {success && (
          <>
            <div className="text-gray-800 mt-4 text-center w-full h-full align-middle py-[16%]">
              <UilCheckCircle className="text-green-600 w-12 h-12 mx-auto" />
              <p className="font-semibold text-lg my-2">
                İsteğiniz başarılı bir şekilde alındı!
              </p>
              <p className="font-light mt-4">
                Demo talep istediğiniz başarılı bir şekilde alındı, en kısa
                sürede uzman ekibimiz sizinle iletişime geçecektir.
              </p>
              <p className="font-light mt-4 text-sm">
                Bizi tercih ettiğiniz için teşekkür ederiz 😊
              </p>
            </div>
          </>
        )}
        {error && (
          <div className="text-red-600 mt-4 font-semibold text-base">
            {errMsg}
          </div>
        )}
      </form>
    </div>
  );
};

export default Content;
