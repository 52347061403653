import { useState } from "react";
import { sendData } from "../utilities/fetch";
import { UilCheckCircle } from "@iconscout/react-unicons";
import { ValidatePhoneNumber } from "../utilities/validation";
import { getCookie } from 'cookies-next';

const MiniDemo = () => {
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>(
    "Lütfen tüm alanları doldurduğunuzdan emin olun !"
  );
  const [name, setName] = useState<string>("");
  const [phonenumber, setPhonenumber] = useState<string>("");

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    const formData = {
      name: name,
      email: "",
      phone: phonenumber,
      company: "",
      source_id: 5,
      description: "Mini Demo Formu",
      utm_campaign: getCookie('utm_campaign', {}),
      utm_date: getCookie('utm_date', {}),
      utm_url: getCookie('utm_uri', {}),
    };

    if (
      name.length < 1 ||
      phonenumber.length < 1
    ) {
      setError(true);
      setErrMsg("Lütfen tüm alanları doldurduğunuzdan emin olun !");
    } else {
      if (ValidatePhoneNumber(phonenumber)) {

        setError(false);
        const ret = await sendData("/leads/create", formData);


        if (ret.status != 200) {
          setError(true);
          setErrMsg("Hata oluştu, lütfen daha sonra deneyin !");
          setSuccess(false);
        } else {
          setError(false);
          setSuccess(true);
          //window.location.replace('/tesekkurler.html');
        }

      } else {
        setError(true);
        setErrMsg("Telefon numaranızı hatalı girdiniz");
      }
    }
  };
  return (
    <>
      {!success && (
        <>
          <div className="rounded bg-blue-1000 text-white text-sm p-8 grid grid-cols-1 md:grid-cols-3">

            <div>
              <p className="font-semibold text-xl md:text-base leading-loose">
                15 gün boyunca ücretsiz deneyin!
              </p>
              <p className="text-gray-400">
                Telefon numaranızı bırakın sizi arayıp bilgi verelim.
              </p>
            </div>
            <form
              className="col-span-2 flex flex-col md:flex-row justify-evenly mt-4 md:mt-0"
              onSubmit={handleSubmit}
            >

              <label className="w-full md:w-1/3">
                <p className="text-xs text-gray-400">İsim ve soyisim</p>
                <input
                  type="text"
                  className="py-2 rounded w-full text-gray-700 px-3 text-sm"
                  onChange={(e) => setName(e.target.value)}
                />
              </label>
              <label className="w-full md:w-1/3">
                <p className="text-xs text-gray-400">Telefon</p>
                <input
                  type="text"
                  className="py-2 rounded w-full text-gray-700 px-3 text-sm"
                  onChange={(e) => setPhonenumber(e.target.value)}
                />
              </label>
              <div>
                <button
                  className="bg-orange-500 text-white px-4 py-2 rounded w-full md:w-min mt-4"
                  type="submit"
                >
                  Gönder
                </button>
              </div>


            </form>
            {error && (
              <>
                <div></div>
                <div className="text-white mt-4 text-xs col-span-2 md:pl-10">
                  {errMsg}
                </div>
              </>
            )}
          </div>
        </>
      )}
      {success && (
        <>
          <div className="rounded bg-blue-1000 text-white text-sm p-8 mt-4 text-center w-full h-full align-middle  mx-auto">
            <UilCheckCircle className="text-green-600 w-12 h-12 mx-auto" />
            <p className="font-semibold text-lg my-2">
              İsteğiniz başarılı bir şekilde alındı!
            </p>
            <p className="font-light mt-4">
              Demo talep istediğiniz başarılı bir şekilde alındı, en kısa
              sürede uzman ekibimiz sizinle iletişime geçecektir.
            </p>
            <p className="font-light mt-4 text-sm">
              Bizi tercih ettiğiniz için teşekkür ederiz 😊
            </p>
          </div>
        </>
      )}


    </>
  );
};

export default MiniDemo;
