import Image from "next/image";
import Link from "next/link";
import { getCdnPath } from "../utilities/functions";
type GroupType = {
  id: number,
  title: string,
  slug: string,
}
interface ICard {
  id: number,
  title: string,
  read: number,
  category_id: number,
  slug: string,
  category?: GroupType,
  file: string;
}




const Card: React.FC<ICard> = ({
  // articleid,
  title,
  // reading,
  // articlegroup,
  slug,
  category,
  file
}) => {


  const image_url = getCdnPath(file);


  return (
    <div className="w-full bg-white flex md:block mb-2">
      <Link href={"/blog/" + slug} className="w-1/3 md:w-full">
        <Image
          className="rounded object-cover w-full h-24 md:h-48"
          src={image_url}
          // src='https://cdn.eticex.com/e-ticaret/images/blog/1/0/5/105.webp'
          alt={title}
          width={373}
          height={192}
        />


      </Link>
      <div className=" w-2/3 px-3 md:w-full md:pl-0 md:py-3">
        <Link
          href={"/blog/kategori/" + category?.slug}
          className="text-md leading-10 md:text-sm md:leading-6 ">{category?.title}
        </Link>
        <Link href={"/blog/" + slug}>

          <p className="text-gray-800 font-semibold text-base tracking-tight mb-2 md:text-lg">
            {title}
          </p>

        </Link>
      </div>
    </div>
  );
};

export default Card;
